<script>
    import
    iControl                    from 'iControl'
    import 'iControl/dist/iControl.css'

    import customers                from './views/Customers.vue'
    import products                 from './views/Products.vue'
    import projects                 from './views/Projects.vue'
    
    import VitescoProducts          from './views/VitescoProduct.vue'
    
    import DewitteProducts          from './views/DewitteProduct.vue'
    import DewitteCategories        from './views/DewitteCategories.vue'
    import DewitteBlog              from './views/DewitteBlog.vue'
    import DewitteCompany           from './views/DewitteCompany.vue'
    import DewitteInstagram         from './views/DewitteInstagram.vue'


    iControl.name = 'Digitalblend'

    iControl.namespaces.push(
        {
            name: "MecaVision",
            desc: "MecaVision Interface.",
            path: "mecavision",
            modules: [
                {
                    name: "Customers",
                    desc: "Customers interface.",
                    path: "customers",
                    component: customers,
                    componentProps: {}
                },
                                {
                    name: "Products",
                    desc: "Products interface.",
                    path: "products",
                    component: products,
                    componentProps: {}
                },
                {
                    name: "Projects",
                    desc: "Projects interface.",
                    path: "projects",
                    component: projects,
                    componentProps: {}
                }
            ],
        },
        {
            name: "Dewitte",
            desc: "Dewitte Interface.",
            path: "dewitte",
            modules: [
                {
                    name: "Products",
                    desc: "Products interface.",
                    path: "products",
                    component: DewitteProducts,
                    componentProps: {}
                },
                {
                    name: "Categories",
                    desc: "Categories interface.",
                    path: "categories",
                    component: DewitteCategories,
                    componentProps: {}
                },
                {
                    name: "Blog",
                    desc: "Blog's interface.",
                    path: "blog",
                    component: DewitteBlog,
                    componentProps: {}
                },
                {
                    name: "Company",
                    desc: "Company's interface.",
                    path: "company",
                    component: DewitteCompany,
                    componentProps: {}
                },
                {
                    name: "Instagram",
                    desc: "Link your instagram.",
                    path: "instagram",
                    component: DewitteInstagram,
                    componentProps: {}
                }
            ]
        }
    )

    iControl.start()

    export default {}
</script>

<style>

    @tailwind base;
    @tailwind components;
    @tailwind utilities;
    @tailwind variants;
    @tailwind forms;

    .light {

        --color-default: rgba(87, 87, 87, 1);
        --color-primary: rgba(220, 219, 15, 1);
        --color-success: rgba(220, 219, 15, 1);
        --color-warning: rgba(242, 145, 52, 1);
        --color-danger: rgba(231, 54, 96, 1);
        --color-info: rgba(134, 134, 134, 1);

        --color-header: rgba(255, 255, 255, 1);
        --color-body: rgba(240, 241, 243, 1);
        --color-body-accent: rgba(255, 255, 255, 1);
        --color-body-accent2: rgba(248, 248, 248, 1);

    }
    .dark {

        --color-default: rgba(217, 217, 217, 1);
        --color-primary: rgba(220, 219, 15, 1);
        --color-success: rgba(220, 219, 15, 1);
        --color-warning: rgba(242, 145, 52, 1);
        --color-danger: rgba(231, 54, 96, 1);
        --color-info: rgba(134, 134, 134, 1);

        --color-header: rgba(63, 63, 63, 1);
        --color-body: rgba(77, 77, 77, 1);
        --color-body-accent: rgba(61, 61, 61, 1);
        --color-body-accent2: rgba(85, 85, 85, 1);

    }
</style>
